import {FeedItem} from "@/api/feed.types";
import {useCommentPostMutation, useGetCommentsQuery} from "@/api/feed";
import React, {useEffect, useRef, useState} from "react";
import {Button} from "@/components/ui/button";
import {Textarea} from "@/components/ui/textarea";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DateRenderer from "@/components/elements/DateRenderer";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import {Media, PublicMedia} from "@/api/media.types";
import ObjectMedias from "@/modules/medias/components/ObjectMedias";
import {cn} from "@/lib/utils";
import {useGenerateCommentMutation} from "@/api/writer";

type PostCommentsProps = {
    post: FeedItem
}
const PostComments = ({post}: PostCommentsProps) => {
    dayjs.extend(relativeTime)

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [commentText, setCommentText] = useState('')
    const [postComment, {isLoading: postCommentLoading}] = useCommentPostMutation()

    const [generateComment, {data: generatedComments, isLoading: generateCommentLoading}] = useGenerateCommentMutation()

    const submitComment = (e: any) => {
        e?.preventDefault()

        if (postCommentLoading) return
        postComment({post_uuid: post.uuid, comment: commentText}).then(() => {
            setCommentText('')
            refetch();
        });
    }

    const {data, refetch} = useGetCommentsQuery({post_uuid: post.uuid})

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus()
        }
    }, [ textareaRef])

    const handleGenerateComments = () => {
        if (generateCommentLoading) return;

        generateComment({
            postUuid: post.uuid
        });
    }

    const handleSelectGeneratedComment = (comment: string) => {
        setCommentText(comment);
        textareaRef.current?.focus();
    }

    const [medias, setMedias] = useState<(Media|PublicMedia)[]>([]);

    return (
        <div>
            <div className="space-y-4 pb-4">
                {data?.data.map((comment) => (
                    <div key={comment.id} className="flex items-start gap-4">
                        <ObjectAvatar
                            object={comment.author}
                            className="size-8"
                        />

                        <div className="text-sm pt-1.5 flex-grow" dangerouslySetInnerHTML={
                            {__html: comment.content}
                        }></div>

                        <p
                            className="text-xs pt-2 flex-shrink-0 opacity-50"
                        >
                            <DateRenderer date={comment.posted_at} format="fromNow"/>
                        </p>
                    </div>
                ))}
            </div>

            <form>
                <Textarea
                    ref={textareaRef}
                    disabled={postCommentLoading}
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    placeholder="Write a comment..."
                    className="w-full border rounded p-2"
                    onClick={handleGenerateComments}
                />

                {generatedComments && generatedComments.items.length > 0 && (
                    <div className="flex flex-col gap-2 w-full pt-2">
                        {generatedComments.items.map((comment, index) => (
                            <Button key={index} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleSelectGeneratedComment(comment)
                            }} variant="outline" className="text-sm w-full block h-30 line-clamp-3">
                                {comment}
                            </Button>
                        ))}
                    </div>
                )}

                <div className="flex gap-2 justify-between items-end pt-4">
                    <ObjectMedias canAdd={true} medias={medias} setMedias={setMedias} className={cn("h-12 flex-grow", {
                        'h-20': medias.length>0
                    })} canAddMultiple={false} addRelevancySearch={commentText} />
                    <Button type="submit" onClick={submitComment} disabled={postCommentLoading} className="flex-shrink-0">Post</Button>
                </div>
            </form>
        </div>
    )
}

export default PostComments;