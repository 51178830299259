import {Button} from "@/components/ui/button";
import {Link, useLocation} from "react-router-dom";
import {clsx} from "clsx";
import ObjectIcon from "@/components/data/ObjectIcon";
import useUser from "@/hooks/useUser";
import {Separator} from "@/components/ui/separator";
import {ReactNode, useEffect, useMemo, useState} from "react";
import {CaretDownIcon} from "@radix-ui/react-icons";
import {cn} from "@/lib/utils";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@/components/ui/dropdown-menu";
import {Ellipsis} from "lucide-react";

type NavigationItemAction = {
    label: string;
    onClick: () => void;
    icon?: ReactNode
}

export type NavigationItemProps = {
    icon?: React.ReactNode;
    fixedIcon?: boolean;
    objectIconProps?: {
        item: any;
        model: string;
        name: string;
    }
    label: string;
    link?: string;
    onClick?: () => void;
    strict?: boolean;
    name: string;
    subItems?: NavigationItemProps[];
    separator?: boolean;
    shortcut?: string;
    actions?: NavigationItemAction[],
    style?: string
    onDrop?: (data: any) => void;
    disabled?: boolean;
    tooltip?: string;
    children?: React.ReactNode;
}

const NavitationItemButton = ({
                                  shortcut,
                                  subItems,
                                  separator,
                                  label,
                                  strict,
                                  objectIconProps,
                                  name,
                                  icon, fixedIcon,
                                  link,
                                  onClick,
                                  isSubitem,
                                  actions,
                                  style,
                                  onDrop,
                                  disabled,
                                  tooltip,
    children
                              }: NavigationItemProps & {
    isSubitem?: boolean
}) => {
    const {
        userSettings
    } = useUser()

    const location = useLocation();
    const isLinkActive = !strict ? link && location.pathname.startsWith(link) : link === location.pathname;
    const hasSubItemActive = (!strict ? subItems?.some((item) => location.pathname.startsWith(item.link ?? '')) : subItems?.some((item) => item.link === location.pathname));

    const [isExpanded, setIsExpanded] = useState(isLinkActive);
    const [hasDragOver, setHasDragOver] = useState(false);

    useEffect(() => {
        if (isLinkActive || hasSubItemActive)
            setIsExpanded(true);
    }, [isLinkActive, hasSubItemActive]);

    const className = cn([
        'group',
        'w-full h-7 py-4 pl-4 pr-1.5 flex justify-start items-center rounded-[3px]',
        'my-px'
    ], {
        'text-foreground/70': !(isLinkActive && !subItems),
        'hover:text-foreground': !(isLinkActive && !subItems),
        'text-foreground': (isLinkActive && !subItems),
        'bg-muted/70': (isLinkActive && !subItems),
        'hover:bg-muted': true,
        'pr-0': subItems,
        'opacity-50': style === 'light',
        'outline outline-primary': hasDragOver,
    })

    const iconParameter = 'navigation_icons.' + name;

    const shortcutName = '⌘' + shortcut?.toUpperCase();

    const hasKeyboard = useMemo(() => {
        return !window?.matchMedia('(pointer:coarse)').matches;
    }, []);

    return (
        <>
            <Button
                variant="ghost"
                className={cn(className, {
                    'pointer-events-none': disabled,
                    'cursor-not-allowed': disabled,
                    'opacity-50': disabled,
                })}
                asChild
                onDragOver={!onDrop ? undefined : (e) => {
                    e.preventDefault();
                    setHasDragOver(true);
                }}
                onDragLeave={!onDrop ? undefined : () => {
                    setHasDragOver(false);
                }}
                onDrop={!onDrop ? undefined : (e) => {
                    e.preventDefault();
                    setHasDragOver(false);
                    onDrop(e?.dataTransfer?.getData('text/plain'));
                }}
                disabled={disabled}
            >
                <Link to={link ?? '#'}
                      onClick={

                          !onClick ? undefined : (e) => {
                              e.preventDefault();
                              onClick();
                          }}
                >
                    {
                        fixedIcon
                            ? <div className="w-7 h-7 flex items-center justify-center">
                                {icon}
                            </div>
                            : <ObjectIcon
                                className={clsx("text-foreground", {
                                    'size-5': isSubitem,
                                    'scale-75': isSubitem,
                                })}
                                defaultIcon={icon}
                                model="user_setting"
                                item={userSettings}
                                name={iconParameter}
                                {...objectIconProps}
                            />
                    }

                    <p
                        className="ml-3 flex items-center w-full gap-2"
                    >
                        <span
                            className='truncate flex-grow'
                        >
                            {label}
                        </span>

                        {hasKeyboard && shortcut && !isLinkActive &&
                            <span
                                className="font-bold bg-foreground/15 text-muted text-xs py-0.5 px-2 rounded-[3px]">
{shortcutName}
                            </span>
                        }

                        {children }

                        {tooltip && !(hasKeyboard && shortcut) && <span
                            className="font-bold bg-foreground/15 text-muted text-xs py-0.5 px-2 rounded-[3px]">
{tooltip}
                             </span>}

                        {actions && (
                            <DropdownMenu
                            >
                                <DropdownMenuTrigger>
                                    <Ellipsis className="size-4 opacity-0 group-hover:opacity-100"
                                    />

                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    {actions.map((action) => (
                                        <DropdownMenuItem
                                            key={action.label}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                action.onClick()
                                            }}
                                        >
                                            <div className="[&>*]:w-4 [&>*]:mr-2">
                                                {action.icon}
                                            </div>
                                            {action.label}
                                        </DropdownMenuItem>
                                    ))}
                                </DropdownMenuContent>
                            </DropdownMenu>
                        )}

                        {subItems &&
                            <Button
                                variant="ghost"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setIsExpanded(!isExpanded);
                                }}
                                className={clsx("!p-2 h-full !bg-transparent", {
                                    'opacity-30': !isExpanded,
                                    'hover:opacity-80': !isExpanded,
                                })}
                            >
                                {isExpanded ? <CaretDownIcon className="size-5"/> :
                                    <CaretDownIcon className="size-5 transform rotate-180"/>}
                            </Button>
                        }
                    </p>

                </Link>
            </Button>

            {subItems && <div
                className={clsx({
                    'ml-4': true,
                    'mb-2': true,
                    'hidden': !isExpanded,
                })}
            >
                {subItems.map((item) => (
                    <NavitationItemButton key={item.name} {...item} isSubitem={true}/>
                ))}
            </div>}


            {separator && <Separator className="my-1"/>}
        </>
    )
}

const NavigationItem = (props: NavigationItemProps) => {
    const {
        userSettings
    } = useUser()

    if (!userSettings) return null;

    return <NavitationItemButton {...props}/>
}

export default NavigationItem;