import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import constants from "@/config/constants";

const baseUrl = `${process.env.REACT_APP_API_HOST}/`;

export const api = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
            headers.set(
                "Authorization",
                `Bearer ${localStorage.getItem(constants.SESSION_TOKEN)}`
            );
            return headers;
        },
    }),
    endpoints: () => ({}),
    tagTypes: [
        "User",
        "Contacts",
        "Companies",
        "Lists",
        "Pipelines",
        "Feeds",
        "Resources",
        "Automations",
        "WriterPosts",
        "Datasets",
        "CalendarEvents",
        "UserAdvices",
        "Medias"
    ],
});
