import useFullLists from "@/hooks/useFullLists";
import {Select, SelectContent, SelectItem, SelectTrigger} from "@/components/ui/select";
import {useEffect, useState} from "react";
import {useGetListPipelineQuery} from "@/api/list";
import {PipelineStage} from "@/api/list.types";
import useFullContactsList from "@/hooks/useFullContactsList";
import Loader from "@/components/elements/Loader";

const SubjectIdeas = () => {
    const {content, isLoading: listsLoading} = useFullLists();
    const [selectedListUuid, setSelectedListUuid] = useState<string | null>(null);

    const {data:pipelineData, isLoading:pipelineLoading} = useGetListPipelineQuery({
        uuid: selectedListUuid ?? ''
    }, {
        skip: !selectedListUuid
    });

    useEffect(() => {
        if (!selectedListUuid && content?.length > 0) {
            setSelectedListUuid(content[0].uuid);
        }
    }, [content, selectedListUuid]);

    return (
        <div className="h-full flex-grow flex flex-col justify-between gap-2">
            <div className="flex items-center justify-end gap-2">
                <Select onValueChange={setSelectedListUuid} value={selectedListUuid??''} disabled={pipelineLoading||listsLoading}>
                    <SelectTrigger className="w-[180px]">
                        {selectedListUuid ? <p>{content?.find((list) => list.uuid === selectedListUuid)?.name}</p> : <p>Select a list</p>}
                    </SelectTrigger>
                    <SelectContent>
                        {content?.map((list) => (
                            <SelectItem key={list.uuid} value={list.uuid}>
                                {list.name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            {!pipelineLoading ? <div className="flex flex-col gap-2 pt-4">
                {pipelineData?.stages.map((stage) => (
                    <PipelineStageCount stage={stage}/>
                ))}
            </div> : <div className="flex items-center justify-center h-full flex-grow">
                <Loader/>
            </div>}
        </div>
    )
}

const PipelineStageCount = ({stage}: { stage: PipelineStage }) => {
    const {content: contacts, isLoading: contactsLoading} = useFullContactsList({
        pipelineStageUuid: stage.uuid
    });

    return <div className="flex items-center justify-between gap-2">
        <p className="text-sm font-bold">{stage.name}</p>
        {contactsLoading && <p className="text-sm opacity-50">Loading...</p>}
        {!contactsLoading && contacts?.length === 0 && <p className="text-sm opacity-50">No contacts</p>}
        {!contactsLoading && contacts?.length > 0 && <p className="text-sm">{contacts.length}</p>}
    </div>
}

export default SubjectIdeas;